import React from 'react';
import { AppBar, SvgIcon, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as EntregoExpressLogo} from '../assets/entrego-express.svg';

const useStyles = makeStyles((theme) => ({
  header: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  logo: {
    width: 'auto',
    height: '3rem',
  },
})); 

export default function Header() {
  const classes = useStyles();
  return (
    <AppBar position="fixed">
      <Grid container>
        <Grid className={classes.header} item xs={12}>
          <SvgIcon className={classes.logo} component={EntregoExpressLogo} viewBox="0 0 4096 1096" />
        </Grid>
      </Grid>
    </AppBar>
  );
}