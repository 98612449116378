import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

const orange = '#EF7724';
const navyBlue = '#044F7C';
const darkGray = '#707070';
const lightGray = '#F8F8F8';
const white = '#FFFFFF';

const base = createMuiTheme({
  palette: {
    primary: {
      main: orange,
    },
    secondary: {
      main: navyBlue,
    },
    background: {
      default: lightGray,
    },
    text: {
      primary: darkGray,
    }
  },
  breakpoints: {
    values: {
      xs: 0, sm: 600, md: 960, lg: 1280, xl: 1600,
    }
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
});

const { typography: { pxToRem } } = base;

const theme = {
  ...base,
  overrides: {
    MuiAppBar: {
      root: {
        [base.breakpoints.down('xl')]: {
          padding: '1rem 6rem',
        },
        [base.breakpoints.down('lg')]: {
          padding: '1rem 6rem',
        },
        [base.breakpoints.down('sm')]: {
          padding: '1rem 3rem',
        },
        [base.breakpoints.up('xl')]: {
          padding: '1rem 6rem',
        },
      },
      colorPrimary: {
        backgroundColor: white,
      }
    },
    MuiButton: {
      root: {
        borderRadius: '4px',
        fontFamily: [
          'Montserrat', 'san-serif'  
        ].join(','),
        fontWeight: 400,
        textTransform: 'capitalize',
      },
      containedPrimary: {
        color: white,
      }
    },
    MuiTab: {
      root: {
        color: '#2E2E2E',
        fontFamily: [
          'Montserrat', 'san-serif'  
        ].join(','),
        fontSize: pxToRem(30),
        fontWeight: 500,
        lineHeight: 0,
        maxWidth: 'unset',
        minHeight: pxToRem(36),
        [base.breakpoints.down('lg')]: {
          padding: '2px 40px 2px 14px',
        },
        [base.breakpoints.down('sm')]: {
          padding: '2px 12px 2px 8px',
          fontSize: pxToRem(16),
          minHeight: pxToRem(24),
        },
        [base.breakpoints.up('lg')]: {
          padding: '2px 120px 2px 14px',
        },
        textTransform: 'none',
        '&:hover': {
          opacity: 0.32,
        },
        '&$selected': {
          color: orange,
          fontWeight: 600,
          opacity: 1,
          borderLeft: 'solid 5px',
          [base.breakpoints.down('sm')]: {
            borderLeft: 'solid 3px',
          },
        },
      },
      textColorInherit: {
        color: '#2E2E2E',
        opacity: 0.16,
      }
    },
    MuiTooltip: {
      tooltip: {
        padding: '16px',
        [base.breakpoints.down('sm')]: {
          padding: '4px 8px',
        },
      },
      popperArrow: {
        '&[x-placement*="bottom"] .MuiTooltip-arrow': {
          [base.breakpoints.up('xl')]: {
            left: '128px !important',
          },
          [base.breakpoints.up('lg')]: {
            left: '122px !important',
          },
          marginTop: '-3em',
          marginLeft: '8px',
          marginRight: '8px',
        }
      },
      arrow: {
        width: '6em',
        height: '3em',  
      },
    },
    MuiTypography : {
      root: {
        fontFamily: [
          'Open Sans',
          'sans-serif'
        ].join(','),
        fontSize: pxToRem(30),
      },
      h1: {
        color: navyBlue,
        fontFamily: [
          'Montserrat', 'san-serif'  
        ].join(','),
        fontWeight: 700,
        fontSize: pxToRem(40),
        [base.breakpoints.down('xl')]: {
          fontSize: pxToRem(40),
        },
        [base.breakpoints.down('sm')]: {
          fontSize: pxToRem(24),
        },
      },
      h6: {
        color: orange,
        fontFamily: [
          'Montserrat', 'san-serif'  
        ].join(','),
        fontSize: pxToRem(30),
        fontWeight: 700,
      },
      subtitle1: {
        fontFamily: [
          'Montserrat', 'san-serif'  
        ].join(','),
        fontWeight: 600,
      },
      body1: {
        fontFamily: [
          'Montserrat', 'san-serif'  
        ].join(','),
        fontWeight: 400,
      },
      body2: {
        fontFamily: [
          'Open Sans',
          'sans-serif'
        ].join(','),
        fontSize: pxToRem(24),
      }
    },
  },
};

export default responsiveFontSizes(theme);