import React, { useEffect, useState, Suspense } from 'react';
import { Backdrop, Box, Grid, IconButton, SvgIcon, 
  // Button, Dialog, DialogActions, 
  // DialogContent, DialogContentText, DialogTitle,
  Tabs, Tab, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Card from '../components/card';
import TabPanel from '../components/tab-panel';
import Tooltip from '../components/tooltip';
import Layout from '../components/layout';

import { ReactComponent as TooltipIcon} from '../assets/tooltip.svg';
import { ReactComponent as ClientIcon} from '../assets/client.svg';
import { ReactComponent as FareyeIcon} from '../assets/fareye.svg';
import { ReactComponent as HelpdeskIcon} from '../assets/helpdesk.svg';
import { ReactComponent as HubIcon} from '../assets/hub.svg';
import { ReactComponent as MyEntregoIcon} from '../assets/myentrego.svg';
import { ReactComponent as TableauIcon} from '../assets/tableau.svg';
import { ReactComponent as TicketIcon} from '../assets/ticket.svg';
import { ReactComponent as WaybillIcon} from '../assets/waybill.svg';
import { ReactComponent as WikiIcon} from '../assets/wiki.svg';

const CLIENT_ID = `${process.env.GATSBY_GOOGLE_CLIENT_ID}`;
const REDIRECT_URI = `${process.env.GATSBY_APP_URL}`;

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  tooltipIcon: {
    zIndex: 1202,
    '&:hover': {
      backgroundColor: 'unset'
    }
  }
}));

function oauth2SignIn() {
  const oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';

  const form = document.createElement('form');
  form.setAttribute('method', 'GET');
  form.setAttribute('action', oauth2Endpoint);

  const params = {
    'client_id': CLIENT_ID,
    'redirect_uri': REDIRECT_URI,
    'response_type': 'token',
    'scope': 'https://www.googleapis.com/auth/drive.metadata.readonly',
    'include_granted_scopes': 'true',
    'state': 'oauth_request',
    'prompt': 'select_account'
  };

  for (const p in params) {
    const input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', p);
    input.setAttribute('value', params[p]);
    form.appendChild(input);
  }

  document.body.appendChild(form);
  form.submit();
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

export default function Index() {
  const classes = useStyles();

  const [value, setValue] = useState(0);
  const [toggle, setToggle] = useState(false);
  // const [open, setOpen] = useState(false);

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  const handleToggleClose = () => {
    setToggle(false);
  }

  const handleToggleOpen = () => {
    setToggle(true);
  };
  
  const useDelayedRender = delay => {
    const [delayed, setDelayed] = useState(true);
    useEffect(() => {
      const timeout = setTimeout(() => setDelayed(false), delay);
      return () => clearTimeout(timeout);
    }, []);
    return fn => !delayed && fn();
  };

  useEffect(() => {
    const fragmentString = window.location.hash.substring(1);
    let params = {};
    let regex = /([^&=]+)=([^&]*)/g, m;
    
    while (m = regex.exec(fragmentString)) {
      params[decodeURIComponent(m[1])] = decodeURIComponent(m[2]);
    }
  
    if (Object.keys(params).length > 0) {
      localStorage.setItem('oauth2-params', JSON.stringify(params));
      if (params['state'] && params['state'] === 'oauth_request') {
        const params = JSON.parse(window.localStorage.getItem('oauth2-params'));
        if (params && params['access_token']) {
          const xhr = new XMLHttpRequest();
          xhr.open('GET',
          'https://www.googleapis.com/drive/v3/about?fields=user&' +
          'access_token=' + params['access_token']);
          xhr.onreadystatechange = function (e) {
            if (xhr.readyState === 4 && xhr.status === 200) {
              console.log(xhr.response);
            } else if (xhr.readyState === 4 && xhr.status === 401) {
              oauth2SignIn();
            }
          };
        } else {
          oauth2SignIn();
        }
      }
    } else {
      oauth2SignIn();
    }
  }, []);

  // const handleClose = () => {
  //   sessionStorage.setItem('entrego-access', true);
  //   setOpen(false);
  // };

  // useEffect(() => {
  //   if (sessionStorage.getItem('entrego-access') === null) {
  //     setOpen(true);
  //   }
  // }, [setOpen]);

  return useDelayedRender(1000)(() => (
    <Layout>
      <Backdrop className={classes.backdrop} open={toggle} />
      {/* <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-title"
        aria-describedby="alert-description"
      >
        <DialogTitle id="alert-title">
          {"Important Reminder"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{ 
              color: '#044F7C', 
              fontWeight: 500, 
              textTransform: 'uppercase' 
            }}
          >
            Unauthorized Access to this site is prohibited
          </DialogContentText>
          <DialogContentText 
            id="alert-description"
            style={{ 
              color: '#044F7C', 
              fontWeight: 400,
            }}  
          >
            You must have an explicit, authorized permission to access 
            or configure this page. Unauthorized attempts and actions to 
            access or use this system may result in civil and/or criminal
            penalties.
          </DialogContentText>
          <DialogActions
            style={{
              justifyContent: 'center'
            }}
          >
            <Button 
              onClick={handleClose} 
              variant="contained" 
              color="primary"
            >
              Ok, I understand
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog> */}
      <Box style={{ display: 'flex', marginBottom: '1rem' }}>
        <Typography variant="h1" style={{ lineHeight: 1.8 }}>
          Entrego Tools Center
        </Typography>
        <Tooltip 
          title={
            <>
              <Typography variant="subtitle1">
                Welcome Ka-Entrego!
                <Typography variant="body1">
                  With this new Tools Center you will be able to have quick
                  access to all our active internal applications and updated
                  documentation portals.
                </Typography>
              </Typography>
            </>
          }
          open={toggle}
          onClose={handleToggleClose}
          onOpen={handleToggleOpen}
        >
          <IconButton className={classes.tooltipIcon} aria-label="tooltip">
            <SvgIcon component={TooltipIcon} viewBox="0 0 32 32" />
          </IconButton>
        </Tooltip>
      </Box>
      <Tabs
        aria-label="navigation-tab"
        value={value} 
        onChange={handleChange}
        TabIndicatorProps={{ style: {background : 'unset'}}}
      >
        <Tab label="System Operations" {...a11yProps(0)} />
        <Tab label="Documentation" {...a11yProps(0)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Grid 
          container 
          spacing={4}
        >
          <Grid item xs={12} sm={6}>
            <Card 
              label="Fareye"
              link="https://www.fareye.co/"
              icon={FareyeIcon}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card 
              label="IT Helpdesk"
              link="http://helpdesk.entrego.com.ph/"
              icon={HelpdeskIcon}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card 
              label="Waybill System"
              link="https://awbtool.entrego.com.ph/"
              icon={WaybillIcon}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card 
              label="Hub Management"
              link="https://hms.entrego.com.ph/"
              icon={HubIcon}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card 
              label="Operations Support"
              link="https://support.entrego.com.ph/"
              icon={TicketIcon}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card 
              label="myEntrego"
              link="https://my.entrego.com.ph/"
              icon={MyEntregoIcon}
            />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid
          direction="column"
          container 
          spacing={4}
        >
          <Grid item xs={12} sm={6}>
            <Card 
              label="Client Onboarding"
              link="http://developer.entrego.com.ph/"
              icon={ClientIcon}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card 
              label="Wiki"
              link="http://wiki.entrego.com.ph/"
              icon={WikiIcon}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card 
              label="Tableau"
              link="https://tableau.entrego.com.ph/"
              icon={TableauIcon}
            />
          </Grid>
        </Grid>
      </TabPanel>
    </Layout>
  ));
};