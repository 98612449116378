import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, CssBaseline } from '@material-ui/core';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Header from './header';
import theme from '../theme';

const useStyles = makeStyles((theme) => ({
  flexWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  contents: {
    marginTop: '7rem',
  },
}));

export default function Layout({ children }) {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className={classes.flexWrapper}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Entrego System Hub</title>
        </Helmet>
        <Header />
        <Container className={classes.contents} component="main">
          {children}
        </Container>
      </div>
    </ThemeProvider>
  );
};