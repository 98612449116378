import React from 'react';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.white,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: '#044F7C',
    boxShadow: theme.shadows[1],
  },
}));

export default function LightTooltip(props) {
  const classes = useStyles();

  return <Tooltip arrow classes={classes} {...props} />
}