import React from 'react';
import PropTypes from 'prop-types';
import { Paper, SvgIcon, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as ChevronIcon} from '../assets/chevron.svg';

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
  },
  paper: {
    alignItems: 'center',
    borderRadius: 0,
    boxShadow: '0px 4px 6px #00000029',
    display: 'flex',
    '& p': {
      flex: 1,
    },
    '& svg': {
      fill: '#EF7724',
      width: '48px',
      height: '48px',
      '&.chevron': {
        width: '32px',
        height: '32px',
      },
    },
    '&:hover': {
      backgroundColor: '#EF7724',
      color: '#FFFFFF',
      '& svg': {
        fill: '#FFFFFF',
      },
    },
    [theme.breakpoints.down('xl')]: {
      padding: '2.5rem',
      minHeight: '140px',
      '& p': {
        margin: '0 2.25rem',
      },
    },
    [theme.breakpoints.down('lg')]: {
      padding: '1.5rem',
      minHeight: '120px',
      '& p': {
        margin: '0 1.5rem',
      },
    },
    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
      '& p': {
        margin: '0 1.25rem',
      },
    },
    [theme.breakpoints.up('xl')]: {
      padding: '3.125rem',
      minHeight: '170px',
      '& p': {
        margin: '0 2.5rem',
      },
    },
  },
}));

export default function Card(props) {
  const { icon, label, link } = props;
  const classes = useStyles();

  return (
    <a 
      className={classes.link} 
      href={link}
      rel="noreferrer"
      target="_blank">
      <Paper className={classes.paper} elevation={2}>
        <SvgIcon
          component={icon} 
          viewBox="0 0 512 512"
        />
        <Typography 
          variant="body2"
        >
          {label}
        </Typography>
        <SvgIcon
          className="chevron"
          component={ChevronIcon} 
          viewBox="0 0 32 32"
        />
      </Paper>
    </a>
  );
};

Card.propTypes = {
  icon: PropTypes.any,
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};